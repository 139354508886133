export const data_9 = [
  {
    title: "九月份場次表",
    data: [
      { time: "14:00 - 14:30", show: "光電音造盤絲洞", title: "沉浸式投影" },
      { time: "14:30 - 15:00", show: "杏仁 ミル", title: "MR 互動體驗" },
      { time: "15:00 – 15:30", show: "杏仁 ミル", title: "MR 互動體驗" },
      {
        time: "16:00 – 16:30",
        show: "鶴之舞 + 河馬圓舞曲",
        title: "MR 互動體驗",
      },
      {
        time: "17:00 – 17:30",
        show: "河馬圓舞曲 + 帝江化生",
        title: "MR 互動體驗",
      },
      { time: "18:00 – 18:30", show: "桃符", title: "沉浸式投影" },
      { time: "18:30 - 19:00", show: "光電音造盤絲洞", title: "沉浸式投影" },
    ],
  },
  {
    title: "九月份場次表",
    data: [
      { time: "14:00 - 14:30", show: "光電音造盤絲洞", title: "沉浸式投影" },
      { time: "14:30 - 15:00", show: "杏仁 ミル", title: "MR 互動體驗" },
      { time: "15:00 – 15:30", show: "杏仁 ミル", title: "MR 互動體驗" },
      {
        time: "16:00 – 16:30",
        show: "鶴之舞 + 河馬圓舞曲",
        title: "MR 互動體驗",
      },
      {
        time: "17:00 – 17:30",
        show: "河馬圓舞曲 + 帝江化生",
        title: "MR 互動體驗",
      },
      { time: "18:00 – 18:30", show: "桃符", title: "沉浸式投影" },
      { time: "18:30 - 19:00", show: "光電音造盤絲洞", title: "沉浸式投影" },
    ],
  },
  {
    title: "九月份場次表",
    data: [
      { time: "14:00 - 14:30", show: "光電音造盤絲洞", title: "沉浸式投影" },
      { time: "14:30 - 15:00", show: "杏仁 ミル", title: "MR 互動體驗" },
      { time: "15:00 – 15:30", show: "杏仁 ミル", title: "MR 互動體驗" },
      {
        time: "16:00 – 16:30",
        show: "鶴之舞 + 河馬圓舞曲",
        title: "MR 互動體驗",
      },
      {
        time: "17:00 – 17:30",
        show: "河馬圓舞曲 + 帝江化生",
        title: "MR 互動體驗",
      },
      { time: "18:00 – 18:30", show: "桃符", title: "沉浸式投影" },
      { time: "18:30 - 19:00", show: "光電音造盤絲洞", title: "沉浸式投影" },
    ],
  },
  {
    title: "九月份場次表",
    data: [
      { time: "14:00 - 14:30", show: "光電音造盤絲洞", title: "沉浸式投影" },
      { time: "14:30 - 15:00", show: "杏仁 ミル", title: "MR 互動體驗" },
      { time: "15:00 – 15:30", show: "杏仁 ミル", title: "MR 互動體驗" },
      {
        time: "16:00 – 16:30",
        show: "鶴之舞 + 河馬圓舞曲",
        title: "MR 互動體驗",
      },
      {
        time: "17:00 – 17:30",
        show: "河馬圓舞曲 + 帝江化生",
        title: "MR 互動體驗",
      },
      { time: "18:00 – 18:30", show: "桃符", title: "沉浸式投影" },
      { time: "18:30 - 19:00", show: "光電音造盤絲洞", title: "沉浸式投影" },
    ],
  },
];

export const month_data = [{ title: "feb_mar_title", index: 4 }];

export const data = [
  {
    id: 5,
    title: "feb_mar_title",
    subtitle: "feb_mar_subtitle",
    subtitle2: "feb_mar_subtitl2",
    data: [
      {
        time: "13:00",
        show: "show_name_23",
        title: "guide_category_1",
      },
      {
        time: "14:00",
        show: "show_name_18",
        title: "guide_category_4",
      },
      {
        time: "15:00",
        show: "show_name_17",
        show2: "show_name_11",
        title: "guide_category_1",
        title2: "guide_category_2",
      },
      {
        time: "16:00",
        show: "show_name_19",
        title: "guide_category_4",
      },
      {
        time: "17:00",
        show: "show_name_13",
        show2: "show_name_10",
        title: "guide_category_1",
        title2: "guide_category_2",
      },
      ,
      {
        time: "18:00",
        show: "show_name_23",
        title: "guide_category_1",
      },
    ],
  },
];
